<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow menu-under-navbar"
    :class="[
      { 'expanded': !isVerticalMenuActive || (isVerticalMenuActive && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded mb-2">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <template v-if="isCommunity">
              <div class="d-flex align-items-center h-100">
                <!--<collective-logo :logo="getImage(collective)" />-->
                <!-- Name and headline -->
                <div v-if="!isVerticalMenuCollapsed || isMouseHovered">
                  <p v-if="title" class="font-weight-extrabold mb-0 ml-1 text-primary h3 truncated-text" >
                    {{ title }}
                  </p>
                  <p v-else class="ml-1 font-weight-extrabold mb-0 text-primary h3 truncated-text" style="max-width: 130px">
                    {{ $t(`collectives.items.${collective.mainType}`).toUpperCase() }}
                  </p>
                  <p v-if="isMenu" class="font-medium-1 ml-1 text-muted mb-0 truncated-text">
                    <b-icon icon="grid" font-scale="0.9" class="mr-25" />
                    {{ $t('settings.menu.title') }}
                  </p>
                  <p v-else class="font-medium-1 ml-1 text-muted mb-0 truncated-text">
                    <b-icon icon="diagram3" font-scale="1" class="mr-25" />
                    {{ $t('settings.menu.structure') }}
                  </p>
                </div>
                <div v-else style="width:50px" class="d-flex align-items-center flex-column">
                  <p v-if="subtitle" class="font-weight-extrabold mb-0 text-primary h3 " style="max-width: 130px">
                    {{ subtitle }}
                  </p>
                  <p v-else class="font-weight-extrabold mb-0  text-primary h3 " style="max-width: 130px">
                    {{ $t(`collectives.items.${collective.mainType}`).slice(0, 2).toUpperCase() }}
                  </p>
                  <p class=" text-muted mb-0  small" style="max-width: 130px">
                    {{ $t('settings.menu.title') }}
                  </p>
                </div>
              </div>
            </template>
            <template v-else>
              <b-link :to="{name: 'Nectios'}" class="d-flex">
                <b-img :src="nectiosLogo" class="avatar ml-1" />
                <div class="ml-50 d-more-than-lg mt-75">
                  <p class="font-weight-extrabold text-dark">
                    {{ titleMy }}
                  </p>
                </div>
              </b-link>
            </template>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle d-flex">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <div class="d-none d-xl-block pt-1 collapse-toggle-icon" />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <div
      v-if="allCommunities.length > 1 && !onlyStructure && isMobile"
      style="border-radius: 0 0.357rem 0.357rem 0;"
      class="mb-2 border-0 mr-1 pointer bg-light-primary"
      @click="isMenu=!isMenu"
    >
      <div class="d-flex align-items-center pl-2 py-50 text-primary">
        <b-icon icon="arrow-repeat" font-scale="1.1" class="ml-75" />
        <p v-if="!isMenu" class="font-medium-1 mb-0 ml-1">
          {{ $t('settings.menu.title') }}
        </p>
        <p v-else class="font-medium-1 mb-0 ml-1">
          {{ $t('settings.menu.structure') }}
        </p>
      </div>
    </div>

    <VuePerfectScrollbar
      v-if="isMenu"
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >

      <VerticalNavMenuItems
        :is-backoffice="isBackoffice"
        :items="appsInMenu"
        class="navigation navigation-main"
      />

      <div 
        v-if="isBancolombia && !isBackoffice"
        class="direct-links-container pb-5"
      >
        <div class="draggable">
          <div class="vertical-nav-menu-header">
            <ul class="pb-5 navigation navigation-main">
              <li
                v-for="item in bancolombiaItemsArray"
                :key="item.title"
                class="nav-item my-25 special-nav-item"
                :class="{
                  'sidebar-group-active active': isActiveItem(item),
                }"
              >
                <b-link
                  class="nav-link"
                  :class="{
                    'bg-light rounded':
                      item.title === $t('backoffice.back') || item.title === 'Back' || item.title === 'Home',
                  }"
                  :to="item.route"
                >
                  <feather-icon
                    v-if="!item.icon || item.icon.includes('fa')"
                    icon="CoffeeIcon"
                    size="20"
                  />
                  <feather-icon 
                    v-else 
                    :icon="item.icon" 
                    size="20" 
                  />
                  <span>
                    {{ translate(item.title) }}
                  </span>
                </b-link>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </VuePerfectScrollbar>

    <VerticalNavMenuItemsStructure v-else 
      :all-communities="allCommunities" 
    />

    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical';
import { BLink, BBadge, BImg } from 'bootstrap-vue';
import { provide, computed, ref } from '@vue/composition-api';
import useAppConfig from '@core/app-config/useAppConfig';
import { $themeConfig } from '@themeConfig';
// import CollectiveLogo from '@core/components/collective-logo/CollectiveLogo.vue';
import { getImageResource } from '@/@core/utils/image-utils';
import GridStatusMixin from '@core/mixins/apps-layout/GridStatusMixin';
import useVerticalNavMenu from './useVerticalNavMenu';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

// import VuePerfectScrollbar from 'vue-perfect-scrollbar';
// import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue';
// import VerticalNavMenuItemsStructure from './components/vertical-nav-menu-items-structure/VerticalNavMenuItemsStructure.vue';

export default {
  name: 'VerticalNavMenu',

  components: {
    VuePerfectScrollbar: () => import('vue-perfect-scrollbar' /* webpackChunkName: "VuePerfectScrollbar" */),
    VerticalNavMenuItems: () => import('./components/vertical-nav-menu-items/VerticalNavMenuItems.vue' /* webpackChunkName: "VerticalNavMenuItems" */),
    VerticalNavMenuItemsStructure: () => import('./components/vertical-nav-menu-items-structure/VerticalNavMenuItemsStructure.vue' /* webpackChunkName: "VerticalNavMenuItemsStructure" */),
    // CollectiveLogo,
    BLink,
    BImg,
    BBadge,
  },
  mixins: [GridStatusMixin],
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
    appsInMenu: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
    isBackoffice: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMenu: true,
      onlyStructure: false,
    };
  },
  watch: {
    '$route' (to, from) {
      // React to route changes if necessary
      this.handleRouteChange(to, from);
    }
  },
  computed: {
    isCommunity() {
      // This information will not come out if we are in my-netios
      return this.$route?.params?.communityId || this.$route?.params?.communitySlug;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    isMobile() {
      return this.isGridDisabled;
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    parentCollective() {
      return this.$store.getters?.parentCollective;
    },
    allCommunities() {
      const allCommunities = [];
      if (
        this.mainCollective != null
        && this.mainCollective.slug != null
        && this.mainCollective.slug !== this.collective.slug
      ) {
        allCommunities.push(this.mainCollective);
      }
      if (
        this.parentCollective != null
        && this.parentCollective?.slug != null
        && this.mainCollective?.slug !== this.parentCollective?.slug
      ) {
        allCommunities.push(this.parentCollective);
        for (let i = 0; i < 100; i++) {
          if (allCommunities[1].parentCollective
          && allCommunities[1].parentCollective?.key !== allCommunities[0].key) {
            const newComunity = allCommunities[1].parentCollective;
            allCommunities.splice(1, 0, newComunity);
          } else {
            break;
          }
        }
      }
      allCommunities.push(this.collective);
      return allCommunities;
    },
    hasStructure() {
      return this.collective.communityMain && this.collective.parentKey && this.collective.communityMain !== this.collective.parentKey;
    },
    isBancolombia() {
      return this.$store.getters.currentCollective.slug === 'nexos-bancolombia';
    },
    bancolombiaItemsArray() {
      return [
        {
          title: "Construyamos juntos",
          route: {
            name: 'Resumen',
            params: {
              communityId: "construyamos-juntos",
            }
          }
        },
        {
          title: "Conócenos",
          route: {
            name: 'Resumen',
            params: {
              communityId: "conocenos",
            }
          }
        },
        {
          title: "Hablemos",
          route: {
            name: 'Resumen',
            params: {
              communityId: "hablemos",
            }
          }
        },
        {
          title: "¿Tienes dudas?",
          route: {
            name: 'Resumen',
            params: {
              communityId: "tienes-dudas",
            }
          }
        },
      ];
    },
  },
  created() {
    // console.log('VerticalNavMenu.vue')
    if (this.appsInMenu && this.appsInMenu.length <= 1 && this.hasStructure && this.isMobile) {
      this.isMenu = false;
      this.onlyStructure = true;
    }
  },
  methods: {
    getImage(collective) {
      if (collective.logoURL != null) {
        return getImageResource(collective.logoURL);
      }
      if (collective.header != null && collective.header.logo_url != null) {
        return getImageResource(collective.header?.logo_url);
      }
    },
    translate(field) {
      return translateTranslationTable(this.locale, field);
    },
    isActiveItem(item) {
      return item.route === this.$route.name;
    },
    handleRouteChange(to, from) {
      // Handle route change logic here
      // console.log('Route changed from', from, 'to', to);
      //Redirect to the route
      // this.$router.replace(to);
      window.location.reload();
    }

  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide('isMouseHovered', isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const collapseTogglerIconFeather = computed(() => (
      collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'
    ));

    // App Name
    const { appName, appLogoImage } = $themeConfig.app;

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    };
  }
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";

.menu-under-navbar {
  top: 57px;
}

.direct-links-container {
  padding: 1rem;
  margin-top: -70px;
}
.special-nav-item:hover {
  background-color: #f8f9fa !important;
}
</style>
